import { apiUrl } from "../../Data";
import methys_logo from "../../methys_logo.png";

const BottomBar = (props) => {
  const handleRedirect = () => {
    window.location.href = apiUrl;
  };
  return (
    <div className="d-flex container-fluid justify-content-between align-items-center bottomContainer">
      <div className=" navMenuLeft d-flex align-items-center justify-content-center">
        <nav className="navmain">
          {/* <ul className="nav-menu">
            <li className="menu-item">
              <a href="https://methyz.parel.co/">Home</a>
            </li>
            <li className="menu-item">
              <a href="https://methyz.parel.co/">About</a>
            </li>
            <li className="menu-item">
              <a href="https://methyz.parel.co/">Shop</a>
            </li>
            <li className="menu-item">
              <a href="https://methyz.parel.co/">Contact</a>
            </li>
          </ul> */}
          {/* {props.name && <div className="toNavText">{props.name}</div>} */}
        </nav>
      </div>
      <div
        className="navMenuLogo d-flex align-items-center justify-content-center"
        onClick={handleRedirect}
      >
        <img
          src={methys_logo}
          alt="Methyz"
          className="logo-img img-responsive"
          width="150"
        />
      </div>
      <div className="navMenuRight d-flex align-items-center justify-content-center"></div>
    </div>
  );
};

export default BottomBar;
