import { AiOutlineTwitter, AiFillInstagram } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";

const TopBar = () => {
  return (
    <div className="topBar">
      <div className="container-fluid d-flex justify-content-around align-items-center">
        <div className="iconLeft"></div>
        <div className="iconRight flex-row-reverse">
          <a
            href="https://www.instagram.com/methyzindia/"
            className="p-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiFillInstagram color={"white"} size={18} />
          </a>

          {/* <a
            href="https://methyz.parel.co/"
            className="p-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiOutlineTwitter color={"white"} size={18} />
          </a> */}

          <a
            href="https://www.facebook.com/Methyz/"
            className="p-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookSquare color={"white"} size={18} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
