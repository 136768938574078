const Colors = (props) => {
  const handleOnclick = (e, f, g) => {
    props.click(e, f, g);
  };
  return (
    <div className="colorboxContainer">
      <div
        className="colorbox"
        style={{ backgroundColor: `${props.color}` }}
        onClick={() => handleOnclick(props.color, null, props.name)}
      ></div>
    </div>
  );
};

export default Colors;
