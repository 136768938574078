import { Canvas } from "@react-three/fiber";
import CameraController from "./CameraController";
import Scene from "./Scene";

import * as THREE from "three";

const ThreeJs = (props) => {
  const handleActivePart = (e, f) => {
    props.isActive(e, f);
  };

  return (
    <Canvas camera={{ fov: 20 }} style={{ height: "60%" }}>
      <CameraController />
      <fog attach="fog" args={["white", 0, 40]} />

      <ambientLight intensity={0.2} />

      <directionalLight
        intensity={0.5}
        castShadow
        shadow-mapSize-height={512}
        shadow-mapSize-width={512}
        position={[0, 10, 0]}
      />

      <pointLight castShadow intensity={0.5} position={[0, 0.8, 0]} />
      <pointLight castShadow intensity={0.5} position={[0, 0, 0]} />
      <pointLight castShadow intensity={0.5} position={[0.9, 0.2, 0.3]} />
      <pointLight castShadow intensity={0.5} position={[-0.6, 0.2, -0.6]} />
      <pointLight castShadow intensity={0.5} position={[-0.6, 0, 0.6]} />

      <Scene
        handleActivePart={handleActivePart}
        data={props.data}
        isObjectLoaded={props.handleObjectLoaded}
        model={props.model}
        modelObject={props.modelObject}
      />
    </Canvas>
  );
};

export default ThreeJs;

function createSpotShadowMesh() {
  const canvas = document.createElement("canvas");
  canvas.width = 128;
  canvas.height = 128;

  const context = canvas.getContext("2d");
  const gradient = context.createRadialGradient(
    canvas.width / 2,
    canvas.height / 2,
    0,
    canvas.width / 2,
    canvas.height / 2,
    canvas.width / 2
  );
  gradient.addColorStop(0.1, "rgba(130,130,130,1)");
  gradient.addColorStop(1, "rgba(255,255,255,1)");

  context.fillStyle = gradient;
  context.fillRect(0, 0, canvas.width, canvas.height);

  const shadowTexture = new THREE.CanvasTexture(canvas);

  const geometry = new THREE.PlaneGeometry();
  const material = new THREE.MeshBasicMaterial({
    map: shadowTexture,
    blending: THREE.MultiplyBlending,
    toneMapped: false,
  });

  const mesh = new THREE.Mesh(geometry, material);
  mesh.rotation.x = -Math.PI / 2;

  return mesh;
}
