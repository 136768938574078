import ThreeJs from "../components/ThreeJS";
import Selector from "../components/Selector";
import { useState, useRef, useCallback } from "react";
import { useEffect } from "react";
import { getproducts, apiUrl } from "../Data";
import LoadingBar from "../components/loadingBar";
import { ThreeDots, ProgressBar, RotatingLines } from "react-loader-spinner";
import axios from "axios";
import "./starter.css";
import { RenderImages, DownloadImages } from "../utils/genImageThreeJs";
import { v4 as uuid } from "uuid";
import { redirect } from "react-router-dom";
import { productVarificationCheck } from "../services";

const unique_id = uuid();

const Starter = (props) => {
  const rendererRef = useRef(null);

  // create a ref for the image data
  const imageDataRef = useRef({});

  const [active, setActive] = useState(false);
  const [objectData, setObjectData] = useState([]);
  const [price, setPrice] = useState("");
  const [productId, setProductId] = useState();
  const [productSlug, setProductSlug] = useState();

  const [item, setItem] = useState(false);
  const [isObjectLoaded, setIsObjectLoaded] = useState(false);

  const [model, setModel] = useState("");

  const [userData, setUserData] = useState([]);

  const [partName, setPartName] = useState("");

  const [processing, setProcessing] = useState(false);

  const [isBlinking, setIsBlinking] = useState(false);

  const [modelObject, setModelObject] = useState(null);

  const [objectImages, setObjectImages] = useState(null);

  const handleActivePart = (e, f) => {
    if (e && f) {
      if (e.object.name) {
        if (
          e.object.name.includes("sole_base") ||
          e.object.name.includes("sole_top")
        ) {
          setPartName("Sole Color");
          setObjectData({ current: e, object: f });
        } else if (e.object.name.includes("strap1")) {
          setPartName("Left Strap Color");
          setObjectData({ current: e, object: f });
        } else if (e.object.name.includes("strap2")) {
          setPartName("Right Strap Color");
          setObjectData({ current: e, object: f });
        } else if (e.object.name.includes("stud1")) {
          setPartName("Left Stud Color");
          setObjectData({ current: e, object: f });
        } else if (e.object.name.includes("stud2")) {
          setPartName("Right Stud Color");
          setObjectData({ current: e, object: f });
        }
      }
    } else {
      setObjectData({ current: null, object: f });
    }
  };

  useEffect(() => {
    if (!isObjectLoaded) {
      const timeoutId = setTimeout(() => {
        setIsObjectLoaded(true);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [isObjectLoaded]);

  useEffect(() => {
    if (!item) {
      axios.get(`${apiUrl}wp-json/mc/v1/init`).then((response) => {
        setItem(response.data);
      });
    }
  }, [item]);

  const handleObjectLoaded = (e) => {
    setIsObjectLoaded(e);
  };

  const handleModel = async (e) => {
    await setIsObjectLoaded(false);

    await setModel(filterBySlug(getproducts.products, e));
  };

  const handlePrice = async (e, f, g) => {
    // setPrice(e);
    setProductId(f);
    setProductSlug(g);
  };

  const getData = (e) => {
    setUserData(e);
  };

  ///create order
  const handleOrder = async () => {
    setProcessing(true);

    let a = [];

    await item.attributes.forEach(async function (x, i) {
      if (x.attribute_name.includes(userData.leftStrap.slug)) {
        a.push({
          id: x.attribute_id,
          name: x.attribute_label,
          option: userData.leftStrap.name,
        });
      }
      if (x.attribute_name.includes(userData.rightStrap.slug)) {
        a.push({
          id: x.attribute_id,
          name: x.attribute_label,
          option: userData.rightStrap.name,
        });
      }
      if (x.attribute_name === userData.sole.slug) {
        a.push({
          id: x.attribute_id,
          name: x.attribute_label,
          option: soleNameChange(userData.sole.name),
        });
      }
      if (x.attribute_name.includes(userData.leftStud.slug)) {
        a.push({
          id: x.attribute_id,
          name: x.attribute_label,
          option: userData.leftStud.name,
        });
      }
      if (x.attribute_name.includes(userData.rightStud.slug)) {
        a.push({
          id: x.attribute_id,
          name: x.attribute_label,
          option: userData.rightStud.name,
        });
      }
      if (x.attribute_name.includes(userData.soleSize.slug)) {
        a.push({
          id: x.attribute_id,
          name: x.attribute_label,
          option: userData.soleSize.name,
        });
      }
      if (x.attribute_name.includes(userData.strapSize.slug)) {
        a.push({
          id: x.attribute_id,
          name: x.attribute_label,
          option: userData.strapSize.name,
        });
      }
    });

    const dataPayload = {
      productId: productId,
      product_slug: productSlug,
      unique_id: unique_id,
      price: price,
      regular_price: price,
      sale_price: price,
      attributes: a,
      sku: "",
      weight: "1",
      stock_quantity: 1,
      stock_status: "instock",
    };

    function checkOptions(array) {
      const soleSize = array.find((obj) => obj.name === "Sole Size");
      const strapSize = array.find((obj) => obj.name === "Strap Size");
      return soleSize.option === "" || strapSize.option === "";
    }

    console.log(dataPayload);

    if (!checkOptions(a)) {
      setIsBlinking(false);

      productVarificationCheck(dataPayload)
        .then(async (response) => {
          if (response.status == 200) {
            if (response.data.flag) {
              window.location.replace(`${response.data.data.url}`);
            } else {
              RenderImages(
                model,
                modelObject,
                productId,
                response.data.data.pv_id,
                response.data.data.url
              );
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setIsBlinking(true);
      setProcessing(false);
    }
  };

  console.log(soleNameChange(userData?.sole?.name));

  return (
    <>
      {/* {props.title && <div className="nameContainer">{props.title}</div>} */}
      {processing && (
        <div style={{ width: "100%", height: "50vh", position: "absolute" }}>
          <LoadingBar loading={RotatingLines} color={"#00DE08"} />
        </div>
      )}
      {!item && (
        <div style={{ width: "100%", height: "50vh", position: "relative" }}>
          <LoadingBar loading={RotatingLines} color={"#00DE08"} />
        </div>
      )}
      {!isObjectLoaded && item && (
        <LoadingBar loading={ThreeDots} color={"#00DE08"} />
      )}
      {item && (
        <>
          <div className="mainContainer">
            <div className="btnSelect shadow" onClick={handleOrder}>
              <span className="spanBtnSelect">Confirm Your Design</span>
              &nbsp;
              {price && <span>₹ {price}</span>}
            </div>

            <ThreeJs
              isActive={handleActivePart}
              data={item}
              handleObjectLoaded={handleObjectLoaded}
              model={model}
              modelObject={(e) => setModelObject(e)}
            />

            <Selector
              price={(e) => setPrice(e)}
              getData={getData}
              partName={partName}
              active={active}
              objectData={objectData}
              data={item}
              model={handleModel}
              handlePrice={handlePrice}
              name={props.name}
              isBlinking={isBlinking}
              handleOrder={handleOrder}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Starter;

function filterBySlug(jsonObject, slug) {
  return jsonObject.filter(function (jsonObject) {
    return jsonObject["slug"] == slug;
  })[0];
}

function filterByName(jsonObject, name) {
  return jsonObject.filter(function (jsonObject) {
    return jsonObject["name"] == name;
  })[0];
}

function soleNameChange(name) {
  if (name) {
    if (name === "Sole 1") {
      return "Grey Blue";
    }
    if (name === "Sole 2") {
      return "Cyan Warm Grey";
    }
    if (name === "Sole 3") {
      return "Dark Blue Hot Pink";
    }
    if (name === "Sole 4") {
      return "Yellow Green";
    }
    if (name === "Sole 5") {
      return "Light Pink Light Violet";
    }
    if (name === "Sole 6") {
      return "Hot Pink Grey";
    }
    if (name === "Sole 7") {
      return "Orange Blue";
    }
    if (name === "Sole 8") {
      return "Black Black";
    }
    if (name === "Sole 9") {
      return "Blue Cyan";
    }
    if (name === "Sole 10") {
      return "Mint Choco";
    }
    if (name === "Sole 11") {
      return "Cyan Warm Grey";
    }
    if (name === "Sole 12") {
      return "Grey Blue";
    }
    if (name === "Sole 13") {
      return "Indigo Blue Lime Green";
    }
    if (name === "Sole 14") {
      return "Electric Blue Dark Aqua";
    }
    if (name === "Sole 15") {
      return "Magenta Lime Green";
    }
    if (name === "Sole 16") {
      return "Vegas Gold Canary Yellow";
    }
  }
}
