import axios from "axios";
import { nodeUrl, apiUrl } from "../Data";
import { Buffer } from "buffer";

export function productVarificationCheck(payload) {
  return axios
    .post(`${nodeUrl}product-varification-check`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

export function uploadImage(formData) {
  const username = "alwin";
  const password = "lVDg 63KT igUO Pxmf ouL6 z2rH";

  // Encode the credentials using Base64
  const token = window.btoa(`${username}:${password}`);
  return axios
    .post(`${apiUrl}wp-json/wp/v2/media`, formData, {
      headers: {
        Authorization: `Basic ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}

export function updateProductVarification(payload) {
  return axios
    .post(`${nodeUrl}update-product`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
}
