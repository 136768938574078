import "bootstrap/dist/css/bootstrap.min.css";
// import { useRoutes } from "react-router-dom";
// import Themeroutes from "./routes/Router";
import Starter from "./views/Starter";
import SvgTest from "./views/svgtest";
import { useState } from "react";
import "./App.css";

import NavBar from "./layouts/NavBar";

function App() {
  const [name, setName] = useState("");
  // const routing = useRoutes(Themeroutes);

  return (
    <div>
      <NavBar name={name} />
      <Starter name={(e) => setName(e)} title={name} />
      {/* <SvgTest /> */}
    </div>
  );
}

export default App;
