import { useState, useEffect } from "react";
import "./styles.css";
import CatItem from "./cateItem";
import Colors from "./colors";
import SoleColor from "./soleColor";
import SizeSelect from "./sizeSelect";
import Select from "react-select";
import { RxHamburgerMenu } from "react-icons/rx";
import ImageGallery from "../../components/ImageGallery";

const ColorContainer = (props) => {
  const [active, setActive] = useState(props.isaActive);
  const [name, setName] = useState("Sole Color");
  const [defaultModel, setDefaultModel] = useState("");

  const [mobileMenu, setMobileMenu] = useState(false);

  const [leftStrap, setLeftStrap] = useState("Blue");
  const [rightStrap, setRighttStrap] = useState("Blue");
  const [leftStud, setLeftStud] = useState("Blue");
  const [rightStud, setRightStud] = useState("Blue");

  const [soleSize, setSoleSize] = useState("");
  const [strapSize, setStrapSize] = useState("");

  const [activeCatSlug, setActiveCatSlug] = useState("kids-raaga");

  const [activeCat, setActiveCat] = useState(0);

  const [isShow, setIsShow] = useState(false);

  const [priceN, setPriceN] = useState("");

  const [soleSizeList, setSoleSizeList] = useState([]);

  const [strapSizeList, setStrapSizeList] = useState([]);

  const [sole, setSole] = useState("");

  useEffect(() => {
    setSoleSizeList(sizeFilter(activeCatSlug, props.data.sole_size));
    setStrapSizeList(sizeFilter(activeCatSlug, props.data.strap_size));

    props.price(setPrice(soleSize, activeCatSlug));

    setPriceN(setPrice(soleSize, activeCatSlug));
  }, [activeCatSlug]);

  const handleClose = () => {
    setActive(false);
  };

  useEffect(() => {
    setSole(setDefaultSole(activeCatSlug));
  }, [activeCatSlug]);

  useEffect(() => {
    setDefaultModel(props.data.products[0].slug);
    props.handlePrice(
      props.data.products[0].price,
      props.data.products[0].product_id,
      props.data.products[0].slug
    );
    props.model(defaultModel);
    props.name(props.data.products[0].name);
    setActiveCatSlug(props.data.products[0].slug);
  }, [defaultModel]);

  useEffect(() => {
    if (props.active) {
      setActive(true);
    }
    if (props.partName) {
      setName(props.partName);
    }
  }, [props]);

  useEffect(() => {
    const data = {
      sole: { name: sole, slug: "sole" },
      leftStrap: {
        name: leftStrap,
        slug: "left-strap",
      },
      rightStrap: {
        name: rightStrap,
        slug: "right-strap",
      },
      leftStud: {
        name: leftStud,
        slug: "left-stud",
      },
      rightStud: {
        name: rightStud,
        slug: "right-stud",
      },
      strapSize: {
        name: strapSize,
        slug: "strap-size",
      },
      soleSize: {
        name: soleSize,
        slug: "sole-size",
      },
    };

    props.getData(data);
  }, [sole, leftStrap, rightStrap, leftStud, rightStud, soleSize, strapSize]);

  const handleClick = (primary, secondary, itemName) => {
    if (name) {
      if (name.includes("Sole Color")) {
        props.objectData.object.materials.Sole_base_left.color.set(secondary);
        props.objectData.object.materials.Sole_base_right.color.set(secondary);

        props.objectData.object.materials.sole_top_left.color.set(primary);
        props.objectData.object.materials.sole_top_right.color.set(primary);

        setSole(itemName);
      } else if (name.includes("Left Strap Color")) {
        props.objectData.object.materials.strap_left.color.set(primary);
        setLeftStrap(itemName);
      } else if (name.includes("Right Strap Color")) {
        props.objectData.object.materials.strap_right.color.set(primary);
        setRighttStrap(itemName);
      } else if (name.includes("Left Stud Color")) {
        props.objectData.object.materials.Stud_left.color.set(primary);
        setLeftStud(itemName);
      } else if (name.includes("Right Stud Color")) {
        props.objectData.object.materials.stud_right.color.set(primary);
        setRightStud(itemName);
      }
    }
  };

  const handleClickCat = (e, f, g) => {
    props.model(e);
    // props.handlePrice(f, g);
    props.handlePrice(f, g, e);
  };

  const handleSoleSize = (e) => {
    props.price(setPrice(e, activeCatSlug));

    setPriceN(setPrice(e, activeCatSlug));

    setSoleSize(e);
  };

  const handleStrapSize = (e) => {
    props.price(setPrice(e, activeCatSlug));

    setPriceN(setPrice(e, activeCatSlug));
    setStrapSize(e);
  };

  const options = [
    {
      label: "Sole",
      value: props.objectData?.object?.materials.Sole_base_left.name,
    },
    {
      label: "Left Strap",
      value: props.objectData?.object?.materials.strap_left.name,
    },
    {
      label: "Right Strap",
      value: props.objectData?.object?.materials.strap_right.name,
    },
    {
      label: "Left Stud",
      value: props.objectData?.object?.materials.Stud_left.name,
    },
    {
      label: "Right Stud",
      value: props.objectData?.object?.materials.stud_right.name,
    },
  ];

  const handlePartChange = (e) => {
    if (e) {
      if (e.value.includes("Sole_base") || e.value.includes("sole_top")) {
        setName("Sole Color");
      } else if (e.value.includes("strap_left")) {
        setName("Left Strap Color");
      } else if (e.value.includes("strap_right")) {
        setName("Right Strap Color");
      } else if (e.value.includes("Stud_left")) {
        setName("Left Stud Color");
      } else if (e.value.includes("stud_right")) {
        setName("Right Stud Color");
      }
    }

    setMobileMenu(false);

    if (mobileMenu) {
      setMobileMenu(false);
    }
  };

  const handleMobleClick = () => {
    if (!mobileMenu) setMobileMenu(true);
  };

  const customStylesA = {
    control: (provided) => ({
      ...provided,
      width: "250px",
      minWidth: "100%",
      height: "100% !important",
    }),
  };

  return (
    <>
      <ImageGallery show={isShow} isShow={(e) => setIsShow(e)} />

      <div className="bottomtray">
        <div className="row">
          <div className="col-12">
            <div className="categoryContainer d-flex">
              <div>
                <label className="labelCustom">Select Category</label>
                <div className="d-flex">
                  {props.data.products.map((item, index) => (
                    <CatItem
                      key={index}
                      isActive={activeCat === index ? true : false}
                      title={item.name}
                      click={() => {
                        handleClickCat(item.slug, item.price, item.product_id);
                        props.name(item.name);
                        setActiveCat(index);
                        setActiveCatSlug(item.slug);
                      }}
                    />
                  ))}
                </div>
              </div>
              <SizeSelect
                dataStrapSize={strapSizeList}
                dataSoleSize={soleSizeList}
                handleSoleSize={handleSoleSize}
                handleStrapSize={handleStrapSize}
                isBlinking={props.isBlinking}
              />
              &nbsp;
              <small
                className="text-muted smallText"
                onClick={() => setIsShow(true)}
              >
                <u>Size Description</u>
              </small>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="partSelect">
              <div>
                <label className="labelCustom">Select Part to Customize</label>
                <Select
                  isSearchable={false}
                  placeholder={"Select Part"}
                  menuPosition="fixed"
                  menuPlacement="top"
                  options={options}
                  onChange={handlePartChange}
                  value={
                    (name.includes("Sole") && {
                      label: "Sole",
                      value:
                        props.objectData?.object?.materials.Sole_base_left.name,
                    }) ||
                    (name.includes("Left Strap Color") && {
                      label: "Left Strap Color",
                      value:
                        props.objectData?.object?.materials.strap_left.name,
                    }) ||
                    (name.includes("Right Strap Color") && {
                      label: "Right Strap Color",
                      value:
                        props.objectData?.object?.materials.strap_right.name,
                    }) ||
                    (name.includes("Left Stud Color") && {
                      label: "Left Stud Color",
                      value:
                        props.objectData?.object?.materials.stud_right.name,
                    }) ||
                    (name.includes("Right Stud Color") && {
                      label: "Right Stud Color",
                      value:
                        props.objectData?.object?.materials.stud_right.name,
                    })
                  }
                  styles={customStylesA}
                  // defaultValue={{
                  //   label: "Sole",
                  //   value:
                  //     props.objectData?.object?.materials.Sole_base_left.name,
                  // }}
                />
              </div>
              &nbsp;&nbsp;
              <div className="w-100">
                <label className="labelCustom">Select Color</label>
                <div className="colorContainer">
                  {name.includes("Sole") && (
                    <>
                      {props.data.sole_colors[`${activeCatSlug}`].map(
                        (item, index) => (
                          <SoleColor
                            key={index}
                            click={handleClick}
                            name={item.name}
                            colorTop={item.primary_color}
                            colorBottom={item.secondary_color}
                          />
                        )
                      )}
                    </>
                  )}
                  {name.includes("Strap") && (
                    <>
                      {props.data.colors.map((item, index) => (
                        <Colors
                          key={index}
                          click={handleClick}
                          color={item.color}
                          name={item.name}
                        />
                      ))}
                    </>
                  )}
                  {name.includes("Stud") && (
                    <>
                      {props.data.colors.map((item, index) => (
                        <Colors
                          key={index}
                          click={handleClick}
                          color={item.color}
                          name={item.name}
                        />
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottomtrayMobile">
        <div className=" d-flex justify-content-center align-items-center">
          {props.data.products.map((item, index) => (
            <div className="categoryContainer d-flex" key={index}>
              <CatItem
                isActive={activeCat === index ? true : false}
                title={item.name}
                click={() => {
                  handleClickCat(item.slug, item.price, item.product_id);
                  props.name(item.name);
                  setActiveCat(index);
                  setActiveCatSlug(item.slug);
                }}
              />
            </div>
          ))}
        </div>
        <div className="row d-flex justify-content-center align-items-center">
          <small className="smallText" onClick={() => setIsShow(true)}>
            <u>Size Description</u>
          </small>
          <div className="sizeContainerMob">
            <SizeSelect
              dataStrapSize={strapSizeList}
              dataSoleSize={soleSizeList}
              handleSoleSize={handleSoleSize}
              handleStrapSize={handleStrapSize}
              isBlinking={props.isBlinking}
            />
          </div>
        </div>
        <div className="row d-flex justify-content-center align-items-center">
          <label className="labelCustom">Select Part to Customize</label>
          <Select
            isSearchable={false}
            styles={customStylesA}
            menuPosition="fixed"
            menuPlacement="top"
            options={options}
            onChange={handlePartChange}
            value={
              (name.includes("Sole") && {
                label: "Sole",
                value: props.objectData?.object?.materials.Sole_base_left.name,
              }) ||
              (name.includes("Left Strap Color") && {
                label: "Left Strap Color",
                value: props.objectData?.object?.materials.strap_left.name,
              }) ||
              (name.includes("Right Strap Color") && {
                label: "Right Strap Color",
                value: props.objectData?.object?.materials.strap_right.name,
              }) ||
              (name.includes("Left Stud Color") && {
                label: "Left Stud Color",
                value: props.objectData?.object?.materials.stud_right.name,
              }) ||
              (name.includes("Right Stud Color") && {
                label: "Right Stud Color",
                value: props.objectData?.object?.materials.stud_right.name,
              })
            }
            // defaultValue={{
            //   label: "Sole",
            //   value: props.objectData?.object?.materials.Sole_base_left.name,
            // }}
          />
        </div>

        <div className="row d-flex justify-content-center align-items-center">
          <label className="labelCustom">Select Color</label>
          <div className="colorContainer">
            {name.includes("Sole") && (
              <>
                {props.data.sole_colors[`${activeCatSlug}`].map(
                  (item, index) => (
                    <SoleColor
                      key={index}
                      click={handleClick}
                      name={item.name}
                      colorTop={item.primary_color}
                      colorBottom={item.secondary_color}
                    />
                  )
                )}
              </>
            )}
            {name.includes("Strap") && (
              <>
                {props.data.colors.map((item, index) => (
                  <Colors
                    key={index}
                    click={handleClick}
                    color={item.color}
                    name={item.name}
                  />
                ))}
              </>
            )}
            {name.includes("Stud") && (
              <>
                {props.data.colors.map((item, index) => (
                  <Colors
                    key={index}
                    click={handleClick}
                    color={item.color}
                    name={item.name}
                  />
                ))}
              </>
            )}
          </div>
        </div>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="someContainer">
            <div className="btnSelectMob shadow" onClick={props.handleOrder}>
              <span className="spanBtnSelectMob">Confirm Your Design</span>
              &nbsp;
              {priceN && <span>₹ {priceN}</span>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ColorContainer;

function filterBySlug(jsonObject, slug) {
  return jsonObject.filter(function (jsonObject) {
    return jsonObject["slug"] == slug;
  })[0];
}

function sizeFilter(slug, object) {
  if (slug === "kids-raaga") {
    return object.filter(
      (size) =>
        size.size === "11c" ||
        size.size === "12c" ||
        size.size === "13c" ||
        size.size === "1" ||
        size.size === "2" ||
        size.size === "3"
    );
  } else if (slug === "adults-ragga" || slug === "adults-yoga") {
    return object.filter(
      (size) =>
        size.size === "4" ||
        size.size === "5" ||
        size.size === "6" ||
        size.size === "7" ||
        size.size === "8" ||
        size.size === "9" ||
        size.size === "10" ||
        size.size === "11" ||
        size.size === "12"
    );
  } else {
    return [];
  }
}

function setPrice(size, slug) {
  if (slug === "kids-raaga") {
    if (size === "1" || size === "2" || size === "3") {
      return "729.00";
    } else if (size === "11c" || size === "12c" || size === "13c") {
      return "709.00";
    } else {
      return "";
    }
  } else if (slug === "adults-ragga" || slug === "adults-yoga") {
    if (
      size === "4" ||
      size === "5" ||
      size === "6" ||
      size === "7" ||
      size === "8" ||
      size === "9" ||
      size === "10" ||
      size === "11" ||
      size === "12"
    ) {
      return "779.00";
    } else {
      return "";
    }
  }
}

function setDefaultSole(slug) {
  if (slug === "kids-raaga") {
    return "Sole 11";
  } else if (slug === "adults-ragga") {
    return "Sole 1";
  } else if (slug === "adults-yoga") {
    return "Sole 6";
  } else {
    return "";
  }
}
