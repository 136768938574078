import { useEffect, useState } from "react";
const CatItem = (props) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    props.click();
  };

  useEffect(() => {
    setIsActive(props.isActive);
  });

  return (
    <>
      <div className={`categoryBtn rounded ${isActive && "catActive"}`}>
        <span className="cateName" onClick={handleClick}>
          {props.title}
        </span>
      </div>
      &nbsp;
    </>
  );
};

export default CatItem;
