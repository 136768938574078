export const getproducts = {
  products: [
    {
      slug: "kids-raaga",
      model_url:
        "https://customize.methyz.com/models/Methiyadi_Feb_Kids_V10.gltf",
    },
    {
      slug: "adults-ragga",
      model_url:
        "https://customize.methyz.com/models/Methiyadi_Feb_Regular_V9.gltf",
    },
    {
      slug: "adults-yoga",
      model_url:
        "https://customize.methyz.com/models/Methiyadi_Feb_Yoga_V9.gltf",
    },
  ],
};

export const apiUrl = "https://methyz.com/";
export const nodeUrl = "https://node.methyz.com/api/";

// "homepage": "https://customize.methyz.com",
