import BottomBar from "./bottomBar";
import "./style.css";
import TopBar from "./topBar";
function NavBar(props) {
  return (
    <>
      <TopBar />
      <BottomBar name={props.name} />
    </>
  );
}

export default NavBar;
