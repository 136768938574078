import { useState, useEffect, useRef } from "react";
import { useLoader, useFrame } from "@react-three/fiber";
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";

let camera, scene, renderer;

const Scene = (props) => {
  const boxRef = useRef();

  const [ready, setReady] = useState(false);
  const [isObjectLoaded, setIsObjectLoaded] = useState(false);

  const [clicked, setClicked] = useState(false);

  const [color, setColor] = useState("#F8F9F9");

  const [defaultColorSole, setDefaultColorSole] = useState("");

  const [defaultColorStudLH, setDefaultColorStudLH] = useState("");
  const [defaultColorStudRH, setDefaultColorStudRH] = useState("");

  const [defaultColorStrapLH, setDefaultColorStrapLH] = useState("");
  const [defaultColorStrapRH, setDefaultColorStrapRH] = useState("");

  const [progress, setProgress] = useState(0);
  const prevProgressRef = useRef(progress);
  // const [gltf, setGltf] = useState(null);

  useFrame(() => {
    if (!clicked) {
      boxRef.current.rotation.y += 0.001;
    }
  });

  const gltf = useLoader(GLTFLoader, props.model.model_url);

  useEffect(() => {
    if (gltf) {
      setDefaultColors();

      props.modelObject(gltf);
      props.isObjectLoaded(true);
      props.handleActivePart(null, gltf);
    } else {
      props.isObjectLoaded(false);
    }
  }, [gltf]);

  const handleClick = (e) => {
    blink(e);

    if (
      e.object.name.includes("sole_top1_left") ||
      e.object.name.includes("sole_base1_left") ||
      e.object.name.includes("sole_top2_right") ||
      e.object.name.includes("sole_base2_right")
    ) {
      props.handleActivePart(e, gltf);
      setClicked(e.object.name);

      // gltf.materials.Sole_base_left.color.set(color);
      // gltf.materials.sole_top_left.color.set(color);

      // gltf.materials.Sole_base_right.color.set(color);
      // gltf.materials.sole_top_right.color.set(color);
    }

    if (e.object.name.includes("stud1_left")) {
      // e.object.material.color.set(color);
      props.handleActivePart(e, gltf);
      setClicked(e.object.name);
    }

    if (e.object.name.includes("stud2_right")) {
      // e.object.material.color.set(color);
      props.handleActivePart(e, gltf);
      setClicked(e.object.name);
    }

    if (e.object.name.includes("strap1_left")) {
      // e.object.material.color.set(color);
      props.handleActivePart(e, gltf);
      setClicked(e.object.name);
    }

    if (e.object.name.includes("strap2_right")) {
      // e.object.material.color.set(color);
      props.handleActivePart(e, gltf);
      setClicked(e.object.name);
    }
  };

  const timeOutFunction = (e) => {
    setTimeout(() => {
      if (
        e.object.name.includes("sole_top1_left") ||
        e.object.name.includes("sole_base1_left") ||
        e.object.name.includes("sole_top2_right") ||
        e.object.name.includes("sole_base2_right")
      ) {
        gltf.materials.Sole_base_left.color.set(defaultColorSole);
        gltf.materials.sole_top_left.color.set(defaultColorSole);

        gltf.materials.Sole_base_right.color.set(defaultColorSole);
        gltf.materials.sole_top_right.color.set(defaultColorSole);

        e.object.material.transparent = true;
        e.object.material.opacity = 1;
      }

      if (e.object.name.includes("stud1_left")) {
        e.object.material.color.set(0xffffff);
      }

      if (e.object.name.includes("stud2_right")) {
        e.object.material.color.set(defaultColorStudLH);
      }

      if (e.object.name.includes("strap1_left")) {
        e.object.material.color.set(defaultColorStrapLH);
      }
      if (e.object.name.includes("strap2_right")) {
        e.object.material.color.set(defaultColorStrapRH);
      }
    }, 300);
  };

  function blink(e) {
    if (
      e.object.name.includes("sole_top1_left") ||
      e.object.name.includes("sole_base1_left") ||
      e.object.name.includes("sole_top2_right") ||
      e.object.name.includes("sole_base2_right")
    ) {
      let material1 = gltf.materials.Sole_base_left;
      const originalColor1 = material1.color.clone();

      let material2 = gltf.materials.sole_top_left;
      const originalColor2 = material2.color.clone();

      let material3 = gltf.materials.Sole_base_right;
      const originalColor3 = material3.color.clone();

      let material4 = gltf.materials.sole_top_right;
      const originalColor4 = material4.color.clone();

      // material.color.set(0xffffff);

      gltf.materials.Sole_base_left.color.set(0xffffff);
      gltf.materials.sole_top_left.color.set(0xffffff);

      gltf.materials.Sole_base_right.color.set(0xffffff);
      gltf.materials.sole_top_right.color.set(0xffffff);

      setTimeout(() => {
        material1.color.copy(originalColor1);
        material2.color.copy(originalColor2);
        material3.color.copy(originalColor3);
        material4.color.copy(originalColor4);
      }, 200);

      setInterval(300);
    }
    if (e.object.name.includes("stud1_left")) {
      let material = e.object.material;
      const originalColor = material.color.clone();

      material.color.set(0xffffff);

      setTimeout(() => {
        material.color.copy(originalColor);
      }, 200);

      setInterval(300);
    }
    if (e.object.name.includes("stud2_right")) {
      let material = e.object.material;
      const originalColor = material.color.clone();

      material.color.set(0xffffff);

      setTimeout(() => {
        material.color.copy(originalColor);
      }, 200);

      setInterval(300);
    }
    if (e.object.name.includes("strap1_left")) {
      let material = e.object.material;
      const originalColor = material.color.clone();

      material.color.set(0xffffff);

      setTimeout(() => {
        material.color.copy(originalColor);
      }, 200);

      setInterval(300);
    }
    if (e.object.name.includes("strap2_right")) {
      let material = e.object.material;
      const originalColor = material.color.clone();

      material.color.set(0xffffff);

      setTimeout(() => {
        material.color.copy(originalColor);
      }, 200);

      setInterval(300);
    }
  }

  const setDefaultColors = async () => {
    if (props.model.slug.includes("adults-yoga")) {
      await gltf.materials.Sole_base_left.color.set(0xf43b77);
      await gltf.materials.Sole_base_right.color.set(0xf43b77);
      await gltf.materials.sole_top_left.color.set(0xe0ded7);
      await gltf.materials.sole_top_right.color.set(0xe0ded7);
    }
    if (props.model.slug.includes("adults-ragga")) {
      await gltf.materials.Sole_base_left.color.set(0xcacaca);
      await gltf.materials.Sole_base_right.color.set(0xcacaca);
      await gltf.materials.sole_top_left.color.set(0x2d69c0);
      await gltf.materials.sole_top_right.color.set(0x2d69c0);
    }
    if (props.model.slug.includes("kids-raaga")) {
      await gltf.materials.Sole_base_left.color.set(0x1b6875);
      await gltf.materials.Sole_base_right.color.set(0x1b6875);
      await gltf.materials.sole_top_left.color.set(0x736869);
      await gltf.materials.sole_top_right.color.set(0x736869);
    }

    setReady(true);
  };

  function handlePointerOver() {
    document.body.style.cursor = "pointer";
  }

  function handlePointerOut() {
    document.body.style.cursor = "default";
  }

  return (
    <>
      {gltf && (
        <group>
          <mesh
            castShadow
            position={[0, 0, 0]}
            scale={[0.05, 0.05, 0.05]}
            rotation={[0, 2.5, 0]}
            ref={boxRef}
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e);
            }}
            onPointerOver={(e) => {
              setClicked(e.object.name);
              handlePointerOver();
            }}
            onPointerOut={(e) => {
              setClicked();
              handlePointerOut();
            }}
          >
            <primitive object={gltf.scene} />
          </mesh>
        </group>
      )}
    </>
  );
};

export default Scene;
