import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import ModalImage from "react-modal-image";

const ImageGallery = (props) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    {
      src: "https://methyz.parel.co/customize/img/size-methyz.jpg",
      alt: "Image 2",
    },
  ];

  function handleNextClick() {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  }

  function handleCloseClick() {
    props.isShow(false);
  }

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0",
      border: "none",
      background: "transparent",

      overflowY: "auto",
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.8)",
    },
  };

  return (
    <div className="modalContainer">
      <Modal
        isOpen={props.show}
        onRequestClose={() => props.isShow(false)}
        style={modalStyles}
      >
        {/* <button
          className="ImageGalleryNextButton ImageGalleryNextButtonLeft"
          onClick={handleNextClick}
        >
          {"<"}
        </button> */}
        <div>
          <button
            className="ImageGalleryCloseButton"
            onClick={handleCloseClick}
          >
            X
          </button>
          <ModalImage
            small={images[currentImageIndex].src}
            large={images[currentImageIndex].src}
            alt={images[currentImageIndex].alt}
          />
        </div>
        {/* <button
          className="ImageGalleryNextButton ImageGalleryNextButtonRight"
          onClick={handleNextClick}
        >
          {">"}
        </button> */}
      </Modal>
    </div>
  );
};

export default ImageGallery;
