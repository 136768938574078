import { useRef, useCallback } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const SvgTest = () => {
  const rendererRef = useRef(null);

  // create a ref for the image data
  const imageDataRef = useRef({});

  // function to load the model and render the images
  const loadModelAndRenderImages = useCallback(() => {
    const loader = new GLTFLoader();
    loader.load(
      "http://localhost:3000/customize/models/MethiyadiKids_V4.gltf",
      (gltf) => {
        // create a scene and add the loaded model to the scene
        const scene = new THREE.Scene();
        scene.add(gltf.scene);

        const ambientLight = new THREE.AmbientLight(0xffffff, 2);
        scene.add(ambientLight);

        // set up the camera positions for the front, top, and right sides of the model
        const cameraPositions = [
          new THREE.Vector3(0, 0, 1), // front view
          new THREE.Vector3(0, 1, 0), // top view
          new THREE.Vector3(1, 0, 0), // right view
        ];

        // create a WebGL renderer and set its size to the desired output image size
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(1000, 1000);
        rendererRef.current = renderer;

        // render each view of the model and save the result as a JPG image
        for (let i = 0; i < cameraPositions.length; i++) {
          const camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
          camera.position.copy(cameraPositions[i]);
          camera.lookAt(gltf.scene.position);
          renderer.render(scene, camera);
          const dataUrl = renderer.domElement.toDataURL("image/jpeg");
          const binaryData = atob(dataUrl.split(",")[1]);
          imageDataRef.current[`view${i + 1}`] = binaryData;
        }

        // construct the API response payload
        const responsePayload = {
          success: true,
          message: "Images rendered successfully",
          data: {
            objectImages: imageDataRef.current,
          },
        };

        // make the API request with the response payload
        console.log(responsePayload);
      }
    );
  }, []);

  // function to handle the API request
  const handleApiRequest = useCallback(() => {
    loadModelAndRenderImages();
    downloadImages();
  }, [loadModelAndRenderImages]);

  function downloadImages() {
    const { view1, view2, view3 } = imageDataRef.current;

    const downloadLink1 = document.createElement("a");
    downloadLink1.download = "view1.jpg";
    downloadLink1.href = "data:image/jpeg;base64," + btoa(view1);
    downloadLink1.click();

    const downloadLink2 = document.createElement("a");
    downloadLink2.download = "view2.jpg";
    downloadLink2.href = "data:image/jpeg;base64," + btoa(view2);
    downloadLink2.click();

    const downloadLink3 = document.createElement("a");
    downloadLink3.download = "view3.jpg";
    downloadLink3.href = "data:image/jpeg;base64," + btoa(view3);
    downloadLink3.click();
  }

  return (
    <div>
      <button onClick={handleApiRequest}>
        Load Model, Render Images, and Make API Request
      </button>
    </div>
  );
};

export default SvgTest;
