import * as THREE from "three";
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { apiUrl, nodeUrl } from "../Data";
import { uploadImage, updateProductVarification } from "../services";
import { RoomEnvironment } from "three/addons/environments/RoomEnvironment.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import axios from "axios";

export const RenderImages = async (
  url,
  payload_model,
  productId,
  pv_id,
  redirectUrl
) => {
  const colorSolebaseleft = getColorHex(
    payload_model.materials.Sole_base_left.color
  );

  const colorSoletopleft = getColorHex(
    payload_model.materials.sole_top_left.color
  );

  const colorSolebaseright = getColorHex(
    payload_model.materials.Sole_base_right.color
  );

  const colorSoletopright = getColorHex(
    payload_model.materials.sole_top_right.color
  );

  const colorStrap_left = getColorHex(payload_model.materials.strap_left.color);

  const colorStrap_right = getColorHex(
    payload_model.materials.strap_right.color
  );

  const colorStud_left = getColorHex(payload_model.materials.Stud_left.color);

  const colorStud_right = getColorHex(payload_model.materials.stud_right.color);

  let camera, scene, renderer;

  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(1000, 1000);
  // renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.outputEncoding = THREE.sRGBEncoding;

  const cameraPositions = [
    url.slug.includes("kids-raaga")
      ? new THREE.Vector3(20, 10, -20)
      : new THREE.Vector3(30, 20, -30),
  ];

  camera = new THREE.PerspectiveCamera(38, 1.1, 0.1, 1000);
  camera.position.copy(cameraPositions[0]);

  const pmremGenerator = new THREE.PMREMGenerator(renderer);

  scene = new THREE.Scene();
  scene.background = new THREE.Color(0xf0f0f0);
  scene.environment = pmremGenerator.fromScene(
    new RoomEnvironment(),
    0.04
  ).texture;

  const loader = new GLTFLoader();

  loader.load(url.model_url, async (gltf) => {
    scene.add(gltf.scene);

    gltf.scene.position.set(0, 0, 0);

    const materials = [];

    scene.traverse((node) => {
      if (node.isMesh) {
        const mesh = node;
        if (mesh.material) {
          materials.push(mesh.material);
        }
      }
    });

    for (const material of materials) {
      if (material.name.includes("Sole_base_left")) {
        material.color.set(colorSolebaseleft);
      }
      if (material.name.includes("sole_top_left")) {
        material.color.set(colorSoletopleft);
      }
      if (material.name.includes("Sole_base_right")) {
        material.color.set(colorSolebaseright);
      }
      if (material.name.includes("sole_top_right")) {
        material.color.set(colorSoletopright);
      }
      if (material.name.includes("strap_left")) {
        material.color.set(colorStrap_left);
      }
      if (material.name.includes("strap_right")) {
        material.color.set(colorStrap_right);
      }
      if (material.name.includes("Stud_left")) {
        material.color.set(colorStud_left);
      }
      if (material.name.includes("stud_right")) {
        material.color.set(colorStud_right);
      }
    }
    const shadowMesh = createSpotShadowMesh();
    shadowMesh.position.y = -1.1;
    shadowMesh.position.z = -0.25;
    shadowMesh.scale.setScalar(2);
    scene.add(shadowMesh);

    camera.lookAt(gltf.scene.position);

    await renderer.render(scene, camera);

    const dataUrl = await renderer.domElement.toDataURL("image/jpeg");

    uploadImageData(dataUrl, url.slug, productId, pv_id, redirectUrl);
  });
};

export const uploadImageData = async (
  dataURI,
  slug,
  productId,
  pv_id,
  redirectUrl
) => {
  const byteString = window.atob(dataURI.split(",")[1]);

  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const ab = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ab[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: mimeString });

  const fileName = `${slug}.png`;
  const file = new File([blob], fileName, {
    type: blob.type,
    lastModified: Date.now(),
  });

  const formData = new FormData();

  formData.append("file", file, "image.jpg");
  formData.append("title", "Image Title");
  formData.append("alt_text", "Image Alt Text");

  await uploadImage(formData)
    .then(async (response) => {
      const updatePaytload = {
        productId: productId,
        pv_id: pv_id,
        imageId: response.data.id,
      };
      await updateProductVarification(updatePaytload)
        .then((response) => {
          if (response.status == 200) {
            window.location.replace(`${redirectUrl}`);
          }
        })
        .catch((error) => console.error);
    })
    .catch((error) => {});
};

function getColorHex(color) {
  return new THREE.Color(`#${color.getHexString()}`);
}

function downloadFile(file) {
  const url = URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = url;
  link.download = file.name;
  link.click();
}

function createSpotShadowMesh() {
  const canvas = document.createElement("canvas");
  canvas.width = 128;
  canvas.height = 128;

  const context = canvas.getContext("2d");
  const gradient = context.createRadialGradient(
    canvas.width / 2,
    canvas.height / 2,
    0,
    canvas.width / 2,
    canvas.height / 2,
    canvas.width / 2
  );
  gradient.addColorStop(0.1, "rgba(130,130,130,1)");
  gradient.addColorStop(1, "rgba(255,255,255,1)");

  context.fillStyle = gradient;
  context.fillRect(0, 0, canvas.width, canvas.height);

  const shadowTexture = new THREE.CanvasTexture(canvas);

  const geometry = new THREE.PlaneGeometry();
  const material = new THREE.MeshBasicMaterial({
    map: shadowTexture,
    blending: THREE.MultiplyBlending,
    toneMapped: false,
  });

  const mesh = new THREE.Mesh(geometry, material);
  mesh.rotation.x = -Math.PI / 2;

  return mesh;
}
