import "./styles.css";

const LoadingBar = (props) => {
  return (
    <div className="loadingBarContainer">
      <props.loading
        strokeColor={props.color}
        strokeWidth="4"
        animationDuration="0.75"
        width="50"
        visible={true}
      />
    </div>
  );
};

export default LoadingBar;
