import Select from "react-select";
import { useState, useEffect } from "react";
const SizeSelect = (props) => {
  const [isBlinking, setIsBlinking] = useState(false);
  const [borderColor, setBorderColor] = useState("rgba(204, 204, 204)"); // Bright red color
  const [sizeSole, setSizeSole] = useState();
  const [sizeStrap, setSizeStrap] = useState();

  const optionsStrap = [
    props.dataStrapSize.map((item) => {
      return {
        label: `${item.size} (${item.size_In_cm}${item.unit})`,
        value: item.size,
      };
    }),
  ];

  const optionsSole = [
    props.dataSoleSize.map((item) => {
      return {
        label: `${item.size} (${item.size_In_cm}${item.unit})`,
        value: item.size,
      };
    }),
  ];

  const handleChangeStrapSize = (e) => {
    props.handleStrapSize(e.value);

    setSizeStrap(e.value);
  };

  const handleChangeSoleSize = (e) => {
    props.handleSoleSize(e.value);
    props.handleStrapSize(e.value);
    setSizeSole(e.value);
    setIsBlinking(false);
  };

  useEffect(() => {
    setIsBlinking(props.isBlinking);
  }, [props.isBlinking]);

  useEffect(() => {
    let intervalId;
    if (isBlinking) {
      intervalId = setInterval(() => {
        setBorderColor((prevColor) =>
          prevColor === "rgba(255, 0, 0, 1)"
            ? "rgba(255, 0, 0, 0.3)"
            : "rgba(255, 0, 0, 1)"
        );
      }, 1000); // Blink every 1 second
    } else {
      setBorderColor("rgba(204, 204, 204)");
    }
    return () => clearInterval(intervalId);
  }, [isBlinking]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      animation: `${isBlinking ? "blink 1s ease-in-out infinite" : ""}`,
      border: `${isBlinking ? "3px" : "1px"} solid ${borderColor}`,
      fontSize: "12px",
      width: "100%",
      lineHeight: 1,
    }),
    "@keyframes blink": {
      "0%": {
        opacity: 0,
      },
      "25%": {
        opacity: 0.25,
      },
      "50%": {
        opacity: 0.5,
      },
      "75%": {
        opacity: 0.75,
      },
      "100%": {
        opacity: 1,
      },
    },
  };

  return (
    <>
      <div>
        <label className="labelCustom">Select Sole Size</label>
        <Select
          isSearchable={false}
          options={optionsSole[0]}
          menuPlacement="top"
          value={optionsSole[0].filter((option) => option.value === sizeSole)}
          width={200}
          placeholder={"Select sole size"}
          onChange={handleChangeSoleSize}
          styles={customStyles}
        />
        <small className="text-muted smallText"></small>
      </div>
      &nbsp; &nbsp;
      <div>
        <label className="labelCustom">Select Strap Size</label>
        <Select
          options={optionsStrap[0]}
          menuPlacement="top"
          value={
            !sizeStrap
              ? optionsStrap[0].filter((option) => option.value === sizeSole)
              : optionsStrap[0].filter((option) => option.value === sizeStrap)
          }
          width={200}
          placeholder={"Select strap size"}
          onChange={handleChangeStrapSize}
          styles={customStyles}
          isSearchable={false}
        />

        {/* <small className="text-muted">Select strap size</small> */}
      </div>
    </>
  );
};

export default SizeSelect;
